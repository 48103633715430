<template>
    <div>
        <el-form :model='formItem' :rules='rules' ref='formItem'>

            <el-form-item  :label='$i18n.t("common.name")' prop="name" :label-width="formLabelWidth">
                <el-input  :placeholder='$i18n.t("common.EnterHholidayTheme")' maxlength="100" v-model="formItem.name" show-word-limit></el-input>
            </el-form-item>


            <el-form-item  prop="countrys" :label='$i18n.t("common.country")' :label-width="formLabelWidth">
                <el-checkbox-group v-model="formItem.countrys">
                    <el-checkbox
                        v-for="(cc, i) in countrysDicts"
                        :label="cc.code"
                        :key="i"
                    >
                        {{ cc.name}}
                    </el-checkbox>
                </el-checkbox-group>

            </el-form-item>

            <el-form-item  prop='recordDate' :label='$i18n.t("common.Displaytime")' :label-width='formLabelWidth'>
                <div class='block'>
                    <el-date-picker
                        v-model='recordDate'
                        type='datetimerange'
                        :range-separator="$i18n.t('common.to')"
                        :start-placeholder="$i18n.t('common.Startdate')"
                        :end-placeholder="$i18n.t('common.Enddate')"
                        format='yyyy-MM-dd HH:mm:ss'
                        value-format='yyyy-MM-dd HH:mm:ss'
                        :default-time="['00:00:00', '23:59:59']"
                        @change='recordDateChange'
                    >
                    </el-date-picker>
                </div>
            </el-form-item>
            <el-form-item prop='status' :label='$i18n.t("common.Enableornot")' :label-width='formLabelWidth'>
                <el-switch v-model='formItem.status'></el-switch>
            </el-form-item>

            <el-form-item  prop='sort' :label='$i18n.t("common.sort")' :label-width='formLabelWidth'>
                    <el-input-number v-model="formItem.sort" :min="0" :max="10000" class='input-width'
                         :placeholder='$i18n.t("common.Topwithlargenumbers")' ></el-input-number>
            </el-form-item>

            <el-form-item  prop="imageUrl" :label='$i18n.t("common.picture")' :label-width="formLabelWidth">
                <el-col :span="6">
                    <imgUpload ref="imageUpload" :img="formItem.imageUrl" @event="changeUrl"> </imgUpload>
                </el-col>
                <el-col :span="6" style="margin-top: 150px">
                    <span>{{$i18n.t("common.Suggestedsize")}}:432*96px，≤1MB</span>
                </el-col>
            </el-form-item>


        </el-form>

        <div  slot="footer" class='dialog-footer'>
            <el-button   @click='closeDialog(false)'>{{ $i18n.t("common.cancel") }}</el-button>
            <el-button   type='primary'  :loading="loading" @click='onSubmit'>{{ $i18n.t("common.confirm") }}</el-button>
        </div>

    </div>
</template>
<script>
 
 

 
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import imgUpload from '@/views/country/imgUpload.vue';


export default {
    components: {
        imgUpload,
        ossUploader,
        moment
    },
    props: {
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        },
        countrysDicts: {
            type: Array,
            default: () => {
                return [];
            }
        },
        brandDicts: {
            type: Array,
            default: () => {
                return [];
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    filters: {
    },
    data() {
        let dateValid = (rule, value, callback) => {
            var selectedValue = this.recordDate;
            if (selectedValue == undefined || selectedValue == null || selectedValue.length < 2 || selectedValue[0] == '') {
                callback(new Error(this.$i18n.t("common.Pleaseselectatime")));
            } else callback();
        };
        return {
            recordDate:this.isEdit ? [moment(this.formItem.startTime ).format('YYYY-MM-DD HH:mm:ss')
                , moment(this.formItem.endTime ).format('YYYY-MM-DD HH:mm:ss')] : [],
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            rules: {
                name: [
                    {
                        required: true, message: this.$i18n.t("common.Pleaseenteraname"), trigger: ['blur', 'change']
                    }
                ],
                countrys: [
                    {
                        required: true, message: this.$i18n.t("common.PleaseSelectACountry"), trigger: 'change'
                    }
                ],
                recordDate: [
                    {
                        required: true,
                        validator: dateValid,
                        trigger: ['blur', 'change']
                    }

                ],
                sort: [
                    {
                        required: true, message: this.$i18n.t("common.Pleaseentersorting"), trigger: ['blur', 'change']
                    }
                ],


            },
            loading: false,
            imageUrl: '',
            formLabelWidth: '120px',
        };
    },
    mounted() {
        this.getOssSignature();
        this.init()
    },
    methods: {
        init(){

            if(this.isEdit){
                if(this.recordDate != null && this.recordDate.length > 1){
                    this.formItem.startTime = this.recordDate[0]
                    this.formItem.endTime = this.recordDate[1]
                }
            }
        },
        recordDateChange(){
          if(this.recordDate != null && this.recordDate.length > 1){
              this.formItem.startTime = this.recordDate[0]
              this.formItem.endTime = this.recordDate[1]
          }
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2b' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },
        onSubmit() {
            this.$refs['formItem'].validate((valid,Object) => {
            if (valid) {

                if( this.formItem.imageUrl==null ||  this.formItem.imageUrl==='' ){
                    this.$message({
                        showClose: false,
                        message: this.$i18n.t("common.Pleaseuploadpictures"),
                        type: 'warning'
                    });
                    return;
                }


                this.$confirm( this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                    type: 'warning',
                }).then(() => {
                    this.loading=true
                    this.formItem.logoConfigCountrys = this.formItem.countrys.map(x => ({countryCode:x}))
                    if (this.isEdit) {
                        this.$store.dispatch('putLogoConfig', this.formItem).then((res) => {
                            this.$message({
                                message: this.$i18n.t('common.SubmittedSuccessfully'),
                                type: 'success',
                                duration: 1000
                            });
                            this.closeDialog(true,this.isEdit);
                        }).finally(() => {
                            this.loading = false;
                        });
                    } else {

                        this.$store.dispatch('addLogoConfig', this.formItem).then((res) => {
                            this.$message({
                                message: this.$i18n.t('common.SubmittedSuccessfully'),
                                type: 'success',
                                duration: 1000
                            });
                            this.closeDialog(true,this.isEdit);
                        }).finally(() => {
                            this.loading = false;
                        });

                    }
                }).catch(() => {
                });
            } else {
                this.$message({
                    message: this.$i18n.t('common.VerificationFailedPleaseEnterTheRequiredFieldsFirst'),
                    type: 'error',
                    duration: 1000
                });
                return false;
            }
        });

        },

        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        },

        fileListChange(fileList, uploaderId, lang) {
            if (fileList.length > 0) {
                this.formItem.imageUrl = fileList[0].key;
            }else{
                this.formItem.imageUrl = '';
            }
        },
        changeUrl(data) {
            this.formItem.imageUrl  = data;
        }
    }
};
</script>
<style scoped>

 

</style>
<style>
.must .el-form-item__label:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}
</style>