<template>
    <div class="app-container">
        <div class="filter-container">
            <el-form :model="searchData" :inline="true" size="small" label-width="140px">


               <el-form-item prop="countryCode">
                    <el-select v-model="searchData.countryCode" clearable :placeholder='$i18n.t("common.country")' >
                        <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>     
               </el-form-item>

                <el-form-item prop="status">
                    <el-select v-model="searchData.status" clearable class="input-width" :placeholder='$i18n.t("common.EnabledOrNot")'>
                        <el-option v-for="item in dictionary.status" :key="item.id" :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-date-picker
                        v-model="time"
                        type="daterange"
                        :range-separator="$i18n.t('common.to')"
                        :start-placeholder="$i18n.t('common.Startdate')"
                        :end-placeholder="$i18n.t('common.Enddate')"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>

                </el-form-item>

                <el-form-item prop="name">
                    <el-input v-model="searchData.name" class='input-width' :placeholder='$i18n.t("common.EnterHholidayTheme")' clearable></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="doQueryList">{{ $i18n.t("common.query") }} </el-button>
                    <el-button @click="handleResetSearch">{{ $i18n.t("common.Reset") }}  </el-button>
                </el-form-item>

            </el-form>
        </div>

        <div>
            <el-button style="margin: 10px 0px 10px 20px" type="primary" icon="el-icon-plus" @click="add()">
                {{ $i18n.t("common.Add1") }}
            </el-button>
        </div>

        <el-table ref="pagedata" v-loading="loading" :data="pageData.list"  border   style="width: 100%;">


            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center"   show-overflow-tooltip >
                <template slot-scope="scope">
                    <span v-for="s in scope.row.logoConfigCountrys" > {{ $dict.getDictValue('countrys', s.countryCode) }}<br /> </span>
                </template>
            </el-table-column>


            <el-table-column prop="name" :label='$i18n.t("common.HolidayTheme")' align="center"  show-overflow-tooltip>
            </el-table-column>


            <el-table-column prop="imageUrl" :label='$i18n.t("common.HolidayImage")' align="center"  >

                <template slot-scope="scope">
                    <el-image
                        style="width: 100px; height: 100px"
                        v-if="scope.row.imageUrl != ''"
                        :src="ossHost + scope.row.imageUrl + '?x-oss-process=style/list_s'"
                        fit="fill"
                    ></el-image> </template>
            </el-table-column>

            <el-table-column prop="status" :label='$i18n.t("common.state")' align="center" >
                <template slot-scope="scope">
                    <el-switch  v-model="scope.row.status" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="startTime" :label='$i18n.t("common.StartTime")' align="center"   show-overflow-tooltip :formatter="formatDate"></el-table-column>
            <el-table-column prop="endTime" :label='$i18n.t("common.EndTime")'  align="center"  show-overflow-tooltip :formatter="formatDate"></el-table-column>
            <el-table-column prop="sort" :label='$i18n.t("common.sort")' align="center"  show-overflow-tooltip></el-table-column>

            <el-table-column prop="operator" :label='$i18n.t("common.Operator")' align="center"  show-overflow-tooltip></el-table-column>

            <el-table-column prop="operationTime" :label='$i18n.t("common.OperatingTime")' align="center" show-overflow-tooltip :formatter="formatDate" >
            </el-table-column>

            <el-table-column :label='$i18n.t("common.operation")' align="center"   >
                <template slot-scope="scope">
                    <el-button @click='edit(scope.row)' type='text' size='small'>{{$i18n.t("common.edit")}}</el-button>
                    <el-button @click='del(scope.row)' type='text' size='small'>{{$i18n.t("common.delete")}}</el-button>
                </template>
            </el-table-column>
        </el-table>


        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page="searchData.page"
                :page-size="searchData.limit"
                :page-sizes="pageSizes"
                layout="total,sizes, prev, pager, next, jumper"
                :total="pageData.total"
            ></el-pagination>
        </div>
 
        <el-dialog :title='isEdit ? $i18n.t("common.edit") : $i18n.t("common.Add")' :visible.sync="dialogFormVisible" v-if="dialogFormVisible" :close-on-click-modal="false">
            <add-update-dialog :isEdit="isEdit" :formItem="formItem" :countrysDicts="countrysDicts"  :brandDicts="brandDicts" @closeDialog="closeDialog" />
        </el-dialog>

    </div>
</template>

<script>
import moment from 'moment';
import AddUpdateDialog from './components/AddUpdateDialog.vue';
import i18n from '@/i18n/i18n';

const defaultFormItem= {
    logoConfigId: null,
    countryCode:null,
    brandCode:null,
    sort: null,
    status: true,
    imageUrl:null,
    name: '',
    logoConfigCountrys: [],
    countrys:[],
    recordDate:[]
}

const defaultDictionary = {  
    status: [{
        id: '1',
        name: i18n.t('common.yes'),
        value: true
    }, {
        id: '2',
        name: i18n.t('common.no'),
        value: false
    }]
};

export default {
    name: 'LogoConfig',
    components: {
       moment,AddUpdateDialog
    },
    data() {
        return {
            dictionary: Object.assign({}, defaultDictionary),
            time:[],
            defaultSearchData:{
                startTime:null,
                endTime:null,
                name: null,
                page: 1,
                limit: 10,
            },
            searchData:{
                startTime:null,
                endTime:null,
                name: null,
                page: 1,
                limit: 10,
            },
            loading: false,
            pageSizes: [10, 20, 30, 50],
            pageData: {},
            ossHost: process.env.VUE_APP_OSS_Head,
            isEdit: false,
            dialogFormVisible: false,
            formItem: Object.assign({}, defaultFormItem),
            countrysDicts: [],
            brandDicts: [],
            dialogConfigLangVisible:false,
            dialogTopSortVisible:false,
        };
    },
    
    created() {
        this.getLangDictItems();
    },
    mounted() {
        this.handleSearchList();
    },
    methods: {
        getLangDictItems() {
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) {
                    this.countrysDicts = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) {
                    this.brandDicts = res;
                }
            });

        },
        //搜索列表
        handleSearchList() {
            this.searchData.page = this.defaultSearchData.page
            this.searchData.limit = this.defaultSearchData.limit 
            this.doQueryList();
        },
        //重置
        handleResetSearch() {
            this.time = [];
            this.searchData = Object.assign({}, this.defaultSearchData);
            this.handleSearchList();
        },
        // 点击页数查询
        handleCurrentChange(val) {
            this.searchData.page = val;
            this.doQueryList();
        },
        // 点击条数查询
        handleSizeChange(val) {
            this.searchData.limit = val;
            this.doQueryList();
        },
        // 列表查询
        doQueryList() {
            if (this.time != null && this.time.length > 0) {
                this.searchData.startTime =  this.time[0];
                this.searchData.endTime =    this.time[1] + ' 23:59:59';
            } else {
                this.searchData.startTime = null;
                this.searchData.endTime = null;
            }

            this.loading=true
            let temp = JSON.stringify(this.searchData, (key, value) => {
                if (value !== null && value !== '') {
                return value;
                }
            });
            let curSates = JSON.parse(temp);
            this.$store.dispatch('getLogoConfigPage', curSates).then(res => {
                this.pageData = res  
            }).finally(() => {
                this.loading = false;
            });
 
        },
        // 新增编辑页面 关闭 
        closeDialog(status,isEdit){
            this.dialogFormVisible=false
            this.formItem=JSON.parse(JSON.stringify(defaultFormItem))    
            if(status){
                if(isEdit){
                    this.doQueryList();
                }else{
                    this.handleSearchList();					
                }   
            }		
        },
        // 新增
        add(){
            this.formItem = JSON.parse(JSON.stringify(defaultFormItem))  
            this.isEdit = false;
            this.dialogFormVisible = true;

        },
        // 编辑
        edit(row){
            this.formItem = JSON.parse(JSON.stringify(row))
            this.formItem.recordDate = [moment(this.formItem.startTime ).format('YYYY-MM-DD HH:mm:ss')
                , moment(this.formItem.endTime ).format('YYYY-MM-DD HH:mm:ss')]
            this.isEdit = true;
            this.dialogFormVisible = true;
        },
        // 删除
        del(row){
            this.$confirm(this.$i18n.t("common.confirmdeletion"), this.$i18n.t("common.prompt"), {
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteLogoConfig', {id: row.logoConfigId}).then(res => {
                    let totalPage = Math.ceil((this.pageData.total - 1) / this.searchData.limit)
                    let currentPage = this.searchData.page > totalPage ? totalPage : this.searchData.page
                    this.searchData.page = currentPage < 1 ? 1 : currentPage
                    this.$message({
                        type: 'success',
                        message: this.$i18n.t('common.Successfullydeleted')
                    });
                    this.doQueryList();
                });
            }).catch(() => {
            });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
</style>
